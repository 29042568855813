<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title>ข้อมูล Production</b-card-title>
      </div>
      <h4 class="m-0">
        <b-link
          v-if="requestDetail.environment === 1"
          @click="onClickEdit"
        >
          ตั้งค่าการเชื่อมต่อ
        </b-link>
      </h4>
    </b-card-header>
    <hr class="m-0">
    <template v-if="requestDetail.environment === 1">
      <b-card-body>
        <b-form-group
          v-if="isApproved"
          label="การใช้งาน API"
          label-for="การใช้งานAPI"
          label-cols="6"
          label-class="text-secondary"
        >
          <b-form-checkbox
            v-model="isCredentialActivated"
            name="การใช้งานAPI"
            checked="true"
            class="text-right text-weight-bolder"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          label="สถานะ"
          label-for="สถานะ"
          label-cols="6"
          label-class="text-secondary"
        >
          <b-form-input
            id="สถานะ"
            :value="requestStatus"
            class="text-right text-weight-bolder"
            :class="{
              'text-success': isApproved,
              'text-warning': !isApproved && !requestDetail.status === 1,
              'text-danger': requestDetail.status === 1
            }"
            plaintext
          />
        </b-form-group>
        <b-form-group
          label="API Key"
          label-for="API"
          label-cols="6"
          label-class="text-secondary"
        >
          <b-form-input
            id="API"
            :value="requestDetail.apiKey || '-'"
            class="text-right text-weight-bolder"
            plaintext
          />
        </b-form-group>

        <b-form-group
          label="Operator Token"
          label-for="token"
          label-cols="6"
          label-class="text-secondary"
        >
          <b-form-input
            id="token"
            :value="requestDetail.operatorToken || 'ยังไม่มีข้อมูล'"
            class="text-right text-weight-bolder"
            plaintext
          />
        </b-form-group>

        <b-form-group
          label="รูปแบบการเชื่อมต่อ"
          label-for="รูปแบบการเชื่อมต่อ"
          label-cols="6"
          label-class="text-secondary"
        >
          <b-form-input
            id="รูปแบบการเชื่อมต่อ"
            :value="requestDetail.integrate_type === 0?'Seamless': requestDetail.integrate_type === 1? 'Transfer' : requestDetail.integrate_type"
            class="text-right text-white"
            plaintext
          />
        </b-form-group>
        <b-form-group
          label="Callback URL"
          label-for="Callback URL"
          label-cols="6"
          label-class="text-secondary"
        >
          <b-form-input
            id="Callback URL"
            :value="requestDetail.callbackUrl ||'ยังไม่มีข้อมูล'"
            class="text-right text-white"
            plaintext
          />
        </b-form-group>
        <b-form-group
          label="Whitelist IP"
          label-for="Whitelist"
          label-cols="6"
          label-class="text-secondary"
        >
          <b-form-input
            v-for="ip in whitelist"
            :id="ip"
            :key="ip"
            :value="ip"
            class="text-right text-white"
            plaintext
          />
        </b-form-group>
      </b-card-body>

      <b-card-footer>
        <div
          v-if="isApproved"
          class="text-center"
        >
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
          >
            <feather-icon
              icon="LogInIcon"
              class="mr-50"
            />
            <span class="align-middle">เข้าใข้งานระบบหลังบ้านของตัวแทน</span>
          </b-button>
        </div>
        <b-row>
          <b-col class="text-center">
            <b-button
              variant="success"
              class="mr-1"
              @click="onApprove"
            >
              อนุมัติ
            </b-button>
            <b-button
              variant="danger"
              @click="onReject"
            >
              ไม่อนุมัติ
            </b-button>
          </b-col>
        </b-row>
      </b-card-footer>
    </template>
    <b-card-body v-else>
      ยังไม่ได้ส่งคำขอ
    </b-card-body>
    <ConfirmModal
      id="approve-production-modal"
      title="ยืนยันการอนุมัติเชื่อมต่อ"
      confirm-text="อนุมัติ"
      @confirm="onConfirmApprove"
    />
    <ConfirmModal
      id="reject-production-modal"
      title="ยืนยันไม่อนุมัติเชื่อมต่อ Production"
      confirm-text="ไม่อนุมัติ"
      confirm-variant="danger"
      @confirm="onConfirmReject"
    />
    <EditRequestModal id="edit-production-modal" />
  </b-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    ConfirmModal: () => import('../../commons/ConfirmModal.vue'),
    EditRequestModal: () => import('../EditRequestModal.vue'),
  },
  data() {
    return {
      isCredentialActivated: false,
    }
  },
  computed: {
    ...mapState({}),
    ...mapGetters(['requestDetail']),
    requestId() {
      const { query } = this.$route
      return query.id ? query.id : ''
    },
    isApproved() {
      const { status } = this.requestDetail
      return status === 2
    },
    requestStatus() {
      const { status } = this.requestDetail
      if (status === 0) {
        return 'รอการอนุมัติ'
      } else if (status === 1) {
        return 'ไม่อนุมัติ'
      } else if (status === 2) {
        return 'อนุมัติ'
      }
      return status
    },
    accountType() {
      const { accountType = 0 } = this.requestDetail
      if (accountType === 1) {
        return 'Owner'
      } else if (accountType === 2) {
        return 'Provider'
      } else if (accountType === 3) {
        return 'Operator'
      }
      return 'ไม่มีข้อมูล'
    },
    whitelist() {
      const { whitelistIp } = this.requestDetail
      return whitelistIp?.split(',') || ['']
    },
  },
  watch: {
    isCredentialActivated(val) {
      if (val) {
        this.suspendCredential({
          apiKey: this.requestDetail.apiKey,
        })
      } else {
        this.activateCredential({
          apiKey: this.requestDetail.apiKey,
        })
      }
    },
  },
  methods: {
    onClickEdit() {
      this.$bvModal.show('edit-production-modal')
    },
    onApprove() {
      this.$bvModal.show('approve-production-modal')
    },
    onReject() {
      this.$bvModal.show('reject-production-modal')
    },
    onConfirmApprove() {},
    onConfirmReject() {},
  },
}
</script>
